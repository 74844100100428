
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.v-tr {
  white-space: nowrap;
  &.selectable-row {
    cursor: pointer;
  }
}
