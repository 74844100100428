
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

@import './assets/scss/colors';
.vue-notification-group {
  z-index: 10000 !important;
  max-width: 400px;
  margin-bottom: 60px;

  .passport-notification {
    display: flex;
    align-items: center;
    min-height: 75px;
    padding: 10px 25px;
    margin: 5px 25px 10px 25px;
    font-weight: normal;
    color: black;
    background-color: white;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;

    .icon {
      flex-shrink: 0;
      width: 54px;
      height: 54px;
      margin-right: 20px;
    }
  }
}
