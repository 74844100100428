
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-field {
  display: block;
  width: 100%;

  .message {
    &.before {
      margin-bottom: var(--g-field-message-gap);
    }
    &.after {
      margin-top: var(--g-field-message-gap);
    }
  }
  .helper-text {
    &.before {
      margin-bottom: var(--g-field-helper-gap, var(--g-field-message-gap));
    }
    &.after {
      margin-top: var(--g-field-helper-gap, var(--g-field-message-gap));
    }
  }
}
