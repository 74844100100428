
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.datepicker-input {
  position: relative;

  &:hover,
  &:focus-within {
    .clear {
      opacity: 1;
    }
  }
  .clear {
    background-color: transparent;
    &:not(&:disabled) {
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
