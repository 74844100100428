
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-breadcrumbs {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  .breadcrumb {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
