
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-progress-circle {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  max-width: 200px;
  height: 100%;
  .progress-circle-wrapper {
    display: block;
  }
  .center-circle {
    fill: black;
    stroke: #dfe4e8;
  }
  .progress-bar {
    fill: none;
    stroke: coral;
    transform: rotateZ(-90deg);
    transform-origin: center;
    will-change: stroke-dashoffset;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
