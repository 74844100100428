
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.gk-tag {
  display: inline-flex;
  align-items: center;
  @include font-dog;

  padding: 7px;
  color: $gk-white;
  background-color: $gk-grey-2;
  border-radius: 3px;

  .g-button {
    padding: 2px;
    margin-left: 4px;
    font-weight: bold;
    ::v-deep {
      .icon {
        width: 8px;
        height: 8px;
        stroke-width: 2px;
      }
    }
  }
}
