
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.gk-table {
  --g-table-row-selector-width: 20px;
  border-collapse: collapse;
  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    border: 1px solid $gk-grey-3;
  }
  ::v-deep {
    .g-checkbox {
      .icon-checkmark {
        margin: 4px;
      }
    }
    .g-table {
      &__cell {
        padding: 15px;
        border: 1px solid;
        border-color: $gk-grey-3;
        border-collapse: collapse;
      }
      thead {
        border: none;
        background-color: $gk-dark-blue;
      }
      &__header {
        padding: 15px;
        color: white;
        text-align: left;
        text-transform: capitalize;
        vertical-align: middle;
        background-color: $gk-dark-blue;
        border: 1px solid;
        border-color: $gk-dark-blue;
        font-weight: normal;

        &.no-results-header {
          height: 40px;
        }

        .icon {
          width: 16px;
          &.after {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
