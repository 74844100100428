
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-thumbnail {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  max-width: 250px;
  overflow: hidden;
  .thumbnail-wrapper {
    position: relative;
    color: white;
    background: lightgray;

    &.disabled {
      opacity: 0.5;
    }
  }

  .badge {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 10px;
    font-size: 1.5em;
    color: white;
    background-color: rgb(131, 212, 179);
  }

  .preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 35%;
    max-height: 55%;
    fill: currentColor;
    transform: translate(-50%, -50%);
    &.image {
      stroke: currentColor;
      stroke-width: 4px;
    }
  }
}
