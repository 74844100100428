
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-radio {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  &.error {
    .icon-radio {
      border-color: red;
    }
  }

  &,
  * {
    box-sizing: border-box;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  .label {
    &.after {
      margin-left: 5px;
    }
    &.before {
      order: -1;
      margin-right: 5px;
    }
  }
  $box-size: 24px;
  .icon-radio {
    display: block;
    width: $box-size;
    height: $box-size;
    border: 1px solid #555;
    border-radius: 50%;
    .outer-circle {
      fill: transparent;
    }
    &.checked {
      border: none;
      .outer-circle {
        fill: #333;
      }
      .inner-circle {
        fill: white;
      }
    }
  }
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;

    /* tweak size and position if needed */
    width: $box-size;
    height: $box-size;
    padding: 0;
    margin: 0;
    cursor: inherit;

    /* hide it visually if 0 screen reader will skip allegedly haven't tested */
    opacity: 0.00001;
  }
}
