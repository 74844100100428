
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-table {
  --g-table-row-selector-width: 50px;

  border-collapse: collapse;
  // table-layout: fixed;
  &__cell,
  &__header {
    &--row-selector {
      .g-checkbox {
        --g-checkbox-size: var(--g-table-row-selector-width);
      }

      width: var(--g-table-row-selector-width);
    }
  }

  td,
  th {
    padding: 0;
  }
}
