// Modals
.content.type--modal {
  background-color: $gk-white;
  color: $gk-dark-blue;
}
.modal-header,
.modal-content {
  width: 100%;
  min-width: 350px;
  padding: 1.75rem 2.5rem; // 28px 40px
  // background-color: $gk-white;
}
.modal-header {
  display: flex;
  border-bottom: 1px solid $gk-light-grey;
}
.modal-title {
  margin-bottom: 1.5625rem; // 25px
  font-size: 0.875rem; // 14px
  font-weight: bold;
  text-transform: uppercase;
  .modal-header & {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.modal-footer {
  margin-top: 2.5rem; // 40px
  text-align: center;
}
