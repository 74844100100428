
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-checkbox {
  --g-checkbox-size: 24px;

  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
  .checkbox {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: var(--g-checkbox-size);
    height: var(--g-checkbox-size);
    border: 1px solid black;
    &.checked {
      background-color: blue;
    }
  }
  .icon-checkmark {
    display: block;
    width: 100%;
    color: white;
  }

  .label {
    line-height: var(--g-checkbox-size);
    &.after {
      margin-left: var(--g-checkbox-label-gap, 5px);
    }
    &.before {
      order: -1;
      margin-right: var(--g-checkbox-label-gap, 5px);
    }
  }

  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;

    /* tweak size and position if needed */
    width: var(--g-checkbox-size);
    height: var(--g-checkbox-size);
    padding: 0;
    margin: 0;
    cursor: inherit;

    /* hide it visually if 0 screen reader will skip allegedly haven't tested */
    opacity: 0.00001;

    // &:focus-visible {
    //   + .checkbox {
    //     position: relative;
    //     &::before {
    //       content: '';
    //       position: absolute;
    //       z-index: -1;
    //       width: 150%;
    //       height: 150%;
    //       padding: 20px;
    //       background-color: rgba(red, 1);
    //       border-radius: 50%;
    //     }
    //     // outline: 1px solid orange;
    //     // outline-offset: 2px;
    //   }
    // }
  }
}
