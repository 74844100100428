
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.gk-pagination {
  height: 24px;
  color: $gk-grey;
  @include bp-sm-phone-landscape {
    max-width: 400px;
  }

  ::v-deep {
    .pagination_item {
      height: 100%;

      &.active {
        color: $gk-grey-2;
      }
    }
    .pagination_break,
    .pagination_button {
      height: 100%;
      padding: 0 15px;
      user-select: none;
    }
    .pagination_button {
      background: none;
      border: none;
    }
  }
}
