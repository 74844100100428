
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.gk-datepicker {
  display: flex;
  align-items: center;

  .datepicker-input {
    width: 100%;
  }

  .separator {
    margin: 25px;
    text-transform: uppercase;
  }
}
