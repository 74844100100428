@import './_reset.scss';
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&family=Titillium+Web:wght@400;600;700&display=swap');
@import '@/assets/scss/_functions';
@import '@/components/local_modules/theme/GK/assets/scss/_transitions';
@import '~vue-loading-overlay/dist/vue-loading.css';
:root {
  --brand-primary: #{$brand-primary};
  --brand-secondary: #{$brand-secondary};
  --brand-accent: #{$brand-accent};
  --brand-white: #{$brand-white};
}
/*stylelint-disable*/
body {
  @include font-adult;
  color: $gk-dark-blue;
}
/*stylelint-enable */
.font-elephant {
  @include font-elephant;
}
.font-camel {
  @include font-camel;
}
.font-horse {
  @include font-horse;
}
.font-lion {
  @include font-lion;
}
.font-adult {
  @include font-adult;
}
.font-child {
  @include font-child;
}
