
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

@import './assets/scss/colors';
.gk-breadcrumbs {
  color: $gk-dark-blue;
  text-transform: uppercase;

  ::v-deep {
    .breadcrumb a {
      color: inherit;
      text-decoration: none;
    }
    .separator {
      margin: 0 4px;
    }
  }
}
