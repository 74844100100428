
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.v-th {
  white-space: nowrap;
  &.sortable {
    cursor: pointer;
    user-select: none;
  }
  .content {
    display: flex;
    align-items: center;
  }
  .icon {
    display: flex;
    align-items: center;
    &.after {
      order: 1;
      margin-left: 5px;
    }
  }
}
