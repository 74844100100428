
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.gk-field {
  &.error {
    ::v-deep {
      > .label,
      > .message {
        color: $gk-red;
      }
    }
  }
  ::v-deep {
    --g-field-message-gap: 5px;
    --g-field-helper-gap: 10px;
    > .label {
      display: block;
      @include font-lion;

      margin-bottom: 9px;
    }
    > .message {
      color: rgba($gk-dark-blue, 0.5);
      @include font-child;
    }
    > .helper-text {
      color: rgba($gk-dark-blue, 0.5);
      @include font-child;
    }

    .datepicker-input {
      width: 100%;
    }
  }
}
