
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-collapsible {
  .header-button {
    width: 100%;
    padding: 0;
    margin: 0;
    color: inherit;
    text-align: left;
    background: none;
    border: none;
  }
  .header {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      vertical-align: middle;
    }
    .icon {
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-left: auto;
      transition: transform var(--g-collapsible-duration);
      transform-origin: center;
      will-change: transform;
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &.open {
    .icon {
      transform: rotate(180deg);
    }
  }
  .content {
    will-change: height;
  }
}
