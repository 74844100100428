
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  list-style: none;

  .pagination_item {
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      .pagination_button {
        font-weight: bold;
      }
    }
    &.li-prev {
      margin-right: auto;
    }
    &.li-next {
      margin-left: auto;
    }
  }
  .pagination_button {
    display: flex;
    align-items: center;
    color: inherit;
    cursor: pointer;
    &:disabled {
      opacity: 0.25;
    }
  }
}
