
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-avatar {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--g-avatar-size, 4rem);
  height: var(--g-avatar-size, 4rem);
  overflow: hidden;
  font-size: var(--g-avatar-size, 4rem);
  background-color: darkgray;
  &.circle {
    border-radius: 50%;

    .image {
      border-radius: 50%;
    }
  }
  .text {
    font-size: var(--g-avatar_text-size, 0.8em);
    color: var(--g-avatar_text-color, inherit);
    text-align: center;
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  .icon {
    max-width: var(--g-avatar_icon-size, 100%);
    max-height: var(--g-avatar_icon-size, 100%);
    color: var(--g-avatar_icon-color, inherit);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity var(--g-avatar-transition-duration, 0.5s) var(--g-avatar-transition-easing, ease-in-out)
      var(--g-avatar-transition-delay, 0s);
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
