
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

@import './assets/scss/colors';
.gk-checkbox {
  --g-checkbox-size: 30px;
  --g-checkbox-label-gap: 10px;
  &.error {
    ::v-deep {
      .checkbox {
        border: 1px solid $gk-red;
      }
    }
  }
  ::v-deep {
    .checkbox {
      box-sizing: content-box;
      border-color: $gk-grey;
      &.checked {
        background-color: $gk-grey;
      }
    }
    .icon-checkmark {
      margin: 7px;
      color: $gk-white;
    }
  }
}
