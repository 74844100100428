
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.gk-chips {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  // width: 100%;
  .gk-tag {
    display: inline-flex;
    flex: 0 0 auto;
    margin: 2.5px;
  }
  ::v-deep {
    .input-element {
      display: inline-flex;
      flex: 1 1 auto;
      width: initial;
      height: 28px;
      padding: 5px;
    }
  }
}
