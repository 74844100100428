
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.s3-uploader {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 100%;
  @include bp-lg-laptop {
    flex-direction: row;
  }
  .files,
  .no-file {
    width: 100%;
    margin-right: 35px;
    @include bp-md-tablet {
      width: 50%;
    }
  }
  .no-file {
    opacity: 0.5;
  }
  .file {
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &-details {
      display: flex;
      align-items: center;
      overflow: hidden;
      @include bp-md-tablet {
        max-width: 400px;
      }
    }
    &-uploaded-icon,
    &-error-icon {
      flex-shrink: 0;
      margin: 5px;
    }
    &-error-icon {
      color: $gk-red;
    }
    &-name {
      padding: 2px 0;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.75;
    }
    &-remove {
      display: flex;
      align-items: center;
      padding: 2px;
      margin-left: auto;
      @include bp-md-tablet {
        margin-left: initial;
      }
      ::v-deep {
        .icon {
          width: 16px;
          height: 16px;
          margin-left: 12px;
        }
      }
    }
    &-input {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      margin-bottom: 10px;
      @include bp-lg-laptop {
        min-width: 400px;
        margin-top: -38px;
      }

      &:not(.disabled) {
        cursor: pointer;
      }
      .disabled {
        opacity: 0.75;
      }

      .helper-text {
        opacity: 0.5;
        @include font-child;
      }

      &-button {
        padding-left: 0;
        &:disabled {
          opacity: 0.5;
        }
        ::v-deep {
          .icon {
            width: 22px;
            height: 18px;
            margin-left: 15px;
          }
        }
      }
    }
  }
  input[type='file'] {
    display: none;
  }
}
