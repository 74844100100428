
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-progress-bar {
  position: relative;
  width: 100%;
  height: var(--g-progress-bar-height, 10px);
  background-color: var(--g-progress-bar-track-color, lightgray);
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--g-progress-bar-progress-color, rgb(45, 157, 255));
  }
}
