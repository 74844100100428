
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

@import '~vue-wysiwyg/dist/vueWysiwyg.css';
.g-input {
  --g-input-action-icon-size: 1.25em;
  --g-input-clear-icon-size: var(--g-input-action-icon-size);
  --g-input-password-reveal-icon-size: var(--g-input-action-icon-size);

  position: relative;
  display: flex;
  align-items: center;
  color: var(--g-input-font-color, black);
  background-color: var(--g-input-background-color, white);
  border: 1px solid var(--g-input-border-color, black);

  &:disabled {
    opacity: 0.5;
  }

  &:focus-within {
    --g-input-border-color: orange;
  }

  .input-action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: var(--g-input-action-icon-size, 1.25em);

    &.clear-button {
      font-size: var(--g-input-clear-icon-size, inherit);
    }
    &.password-reveal-button {
      font-size: var(--g-input-password-reveal-icon-size, inherit);
    }
  }
  .icon {
    flex-shrink: 0;
    color: var(--g-input-icon-color, inherit);

    &-left {
      color: var(--g-input-icon-left-color, inherit);
    }
    &-right {
      color: var(--g-input-icon-right-color, inherit);
    }
  }
  .input-element {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: inherit;
    color: inherit;
    background-color: inherit;
    border: none;
    border-radius: inherit;
    outline: none;
    // TODO Figure out solution for autofill styling on login page
    // Chrome webkit autofill style touch up
    // &:-webkit-autofill,
    // &:-webkit-autofill:hover,
    // &:-webkit-autofill:focus {
    //   border: none;
    //   border-bottom: 1px solid $input-border-light;
    //   -webkit-box-shadow: none;
    //   transition: background-color 5000s ease-in-out 0s;
    //   -webkit-text-fill-color: $text-color-dark;
    // }
    &:not([type='submit'], [type='range']) {
      appearance: none;
    }
    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
  &.type--textarea {
    flex-direction: column;
  }
  textarea {
    &.autoResize {
      resize: none;
    }
  }
  input:-webkit-autofill::first-line {
    font-family: inherit;
  }
}
