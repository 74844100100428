.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 150ms ease;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.navigation {
  &-enter-active,
  &-leave-active {
    transition: opacity 100ms ease-in-out;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.notification {
  &-enter-active,
  &-leave-active {
    transition: transform 1s;
  }
  &-enter,
  &-leave-to {
    transform: translateX(-100%);
  }
  &-move {
    transition: all 500ms;
  }
}

.scale {
  &-enter-active,
  &-leave-active {
    transition: all 300ms;
  }
  &-enter,
  &-leave-to {
    transform: scale(0);
  }
}

.slide {
  &-enter-active,
  &-leave-active {
    transition: all 300ms;
  }
  &-enter,
  &-leave-to {
    transform: translateX(100%);
  }
}
