
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-switch {
  --g-switch-width: 55px;
  --g-switch-height: 24px;
  --g-switch-track-color: rgb(163, 163, 163);
  --g-switch-track-color--checked: rgb(61, 184, 255);
  --g-switch-thumb-color--checked: var(--g-switch-thumb-color);
  --g-switch-thumb-color: rgb(53, 54, 65);
  --g-switch-thumb-gap: 2px;
  --g-switch-thumb-size: calc(var(--g-switch-height) - calc(var(--g-switch-thumb-gap) * 2));
  input:focus-visible + .track {
    outline: 1px solid black;
  }
  .track {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: var(--g-switch-width);
    height: var(--g-switch-height);
    background-color: var(--g-switch-track-color);
    transition: background-color 200ms;
    will-change: background-color;

    &.rounded {
      border-radius: 9999px;
    }
    &.checked {
      background-color: var(--g-switch-track-color--checked);
      // This one's a little goofy. I'm moving the track by setting the flex-grow on this spacer once checked
      // It just allows for the thumb to be customized with less hardcoded parameters
      .flex-spacer {
        flex: 1;
      }
      .thumb {
        background-color: var(--g-switch-thumb-color--checked);
      }
    }

    .thumb {
      box-sizing: border-box;
      display: table;
      align-items: center;
      justify-content: center;
      width: var(--g-switch-thumb-size);
      height: var(--g-switch-thumb-size);
      margin: var(--g-switch-thumb-gap);
      content: '';
      background-color: var(--g-switch-thumb-color);
      &.rounded {
        border-radius: 9999px;
      }
    }
    .flex-spacer {
      transition: flex 200ms;
      will-change: flex;
    }
  }
}
