
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

@import './assets/scss/colors';
.passport-switch {
  --g-switch-width: 40px;
  --g-switch-height: 18px;
  --g-switch-track-color: #{rgba($gk-grey, 0.3)};
  --g-switch-track-color--checked: #{$gk-grey};
  --g-switch-thumb-color: #{$gk-white};
  --g-switch-thumb-gap: 1px;
  --g-checkbox-size: var(--g-switch-height);
  --g-checkbox-label-gap: 10px;

  &.error {
    ::v-deep {
      .track {
        border: 1px solid $gk-red;
      }
    }
  }
  ::v-deep {
    .track {
      .thumb {
        box-shadow: 0 1px 3px #0000001a;
      }
    }
  }
}
