
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

// Standard GButton overrides
.g-button {
  $color-primary: rgb(119, 119, 206);

  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  font-family: inherit;
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  background: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  // decorative styles
  background-color: $color-primary;
  border: 0;
  appearance: none;

  &.unstyled {
    background: none;
    border: none;
  }

  &.rounded {
    border-radius: 9999px;
  }
  &.outline {
    background-color: transparent;
    border: 1px solid;
  }
  &.text {
    background-color: transparent;
    border: none;
  }
  &.link {
    color: inherit;
    background: none;
    border-radius: 0;
    &:not(:disabled) {
      &:hover {
        text-decoration: underline;
      }
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  .icon {
    &.left {
      margin-right: var(--g-button-icon-left-gap, var(--g-button-icon-gap));
    }
    &.right {
      margin-left: var(--g-button-icon-right-gap, var(--g-button-icon-gap));
    }
  }
}
