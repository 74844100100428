
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.g-divider {
  --g-divider-color: currentColor;
  --g-divider-thickness: 1px;
  --g-divider-style: solid;

  border-color: var(--g-divider-color);
  border-width: var(--g-divider-thickness);
  &.horizontal {
    display: block;
    width: 100%;
    border-bottom-style: var(--g-divider-style);
  }
  &.vertical {
    align-self: stretch;
    border-right-style: var(--g-divider-style);
  }
}
