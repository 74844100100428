
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

@import '@/assets/scss/modals';
@import '@/assets/scss/theme';
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page {
  min-height: 100vh;
}
.notify {
  position: absolute;
  right: 0;
}
