
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.gk-dropdown {
  font-size: 13px;
  &.error {
    ::v-deep {
      .multiselect {
        &__tags {
          border-color: $gk-red;
        }
      }
    }
  }
  ::v-deep {
    .styled-tag {
      margin: 2.5px;
    }
    .multiselect {
      box-sizing: border-box;
      &__placeholder {
        padding: 0;
        padding-left: 5px;
        margin: 0;
        line-height: 38px;
        color: #adadad;
      }
      &__input {
        @include font-adult;

        padding-right: 30px;
        margin-bottom: 0;
        line-height: 38px;
        background-color: transparent;
        &::placeholder {
          color: #adadad;
        }
      }
      &__single {
        padding: 0;
        padding-left: 5px;
        margin: 0;
        font-size: inherit;
        line-height: 38px;
        background-color: transparent;
      }
      &__select {
        top: 0;
        right: 0;
        box-sizing: border-box;
        width: 20px;
        height: 100%;
        padding: 0;
        margin-right: 10px;
        color: $gk-grey;
      }
      &__tags {
        min-height: 50px;
        padding: 5px;
        border: 1px solid #cbcbcb;
        border-radius: 0;
      }
      &__content-wrapper {
        border-color: #cbcbcb;
      }
      &__option {
        &--highlight {
          background: $gk-dark-blue;
        }
        &--selected {
          &.multiselect__option--highlight {
            // background: $gk-red;
          }
        }
      }
    }
  }
}
